import Vue from 'vue'
import App from './App.vue'
import router from './router'
import http from './api/http'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "./assets/css/font-awesome.min.css";
import "./assets/css/iconfont.css";
import './assets/css/style.css';
import store from './store/index'
import * as echarts from 'echarts'
import VueTianditu from "vue-tianditu";
import dataV from '@jiaminghi/data-view'

Vue.use(dataV)
import Toasted from 'vue-toasted';
import {
    debounce
} from './plugins/common.js';


Vue.use(ElementUI);

Vue.config.productionTip = false

Vue.prototype.$nd = "2020";





Vue.use(VueTianditu, {
    v: "4.0",
    tk: "93724b915d1898d946ca7dc7b765dda5"
});
Vue.directive("dragscroll", function(el) {
    el.onmousedown = function(ev) {
        // console.log(ev)
        const disX = ev.clientX;
        const disY = ev.clientY;
        const originalScrollLeft = el.scrollLeft;
        const originalScrollTop = el.scrollTop;
        const originalScrollBehavior = el.style["scroll-behavior"];
        const originalPointerEvents = el.style["pointer-events"];
        el.style["scroll-behavior"] = "auto";
        el.style["cursor"] = "grabbing";
        document.onmousemove = function(ev) {
            ev.preventDefault();
            const distanceX = ev.clientX - disX;
            const distanceY = ev.clientY - disY;
            el.scrollTo(
                originalScrollLeft - distanceX,
                originalScrollTop - distanceY
            );
            // el.style["pointer-events"] = "none";
            document.body.style["cursor"] = "grabbing";
        };
        document.onmouseup = function() {
            document.onmousemove = null;
            document.onmouseup = null;
            el.style["scroll-behavior"] = originalScrollBehavior;
            el.style["pointer-events"] = originalPointerEvents;
            el.style["cursor"] = "grab";
        };
    };
});



Vue.prototype.$PileTable = [{
        value: "G",
        title: "国道",
        index: 0,
        layerId: 17
    },
    {
        value: "S",
        title: "省道",
        index: 1,
        layerId: 18
    },
    {
        value: "X",
        title: "县道",
        index: 2,
        layerId: 19
    },
    {
        value: "Y",
        title: "乡道",
        index: 3,
        layerId: 20
    },
    {
        value: "Z",
        title: "专用公路",
        index: 4,
        layerId: 21
    },
    {
        value: "C",
        title: "村道",
        index: 5,
        layerId: 22
    },
];

Vue.prototype.$toastr = debounce(function(text) {
    this.$toasted
        .show(text, {
            position: "top-center"
        })
        .goAway(1500);
}, 500)

var vue = new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

Vue.prototype.http = http;
Vue.prototype.http.init(vue);
Vue.prototype.$echarts = echarts
