import axios from 'axios'
import store from '../store/index'
axios.defaults.timeout = 50000000;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
import { Loading, Message } from 'element-ui';

let loadingInstance;
let loadingStatus = false;
// if (process.env.NODE_ENV == 'development') {
//     axios.defaults.baseURL = 'http://127.0.0.1:9991/';
// }
// else if (process.env.NODE_ENV == 'debug') {
//     axios.defaults.baseURL = 'http://127.0.0.1:9991/';
// }
// else if (process.env.NODE_ENV == 'production') {
axios.defaults.baseURL = 'http://82.156.50.94:8002/';
// axios.defaults.baseURL = 'http://192.168.50.87:9991/';
// axios.defaults.baseURL = 'http://192.168.1.114:9991/';

// axios.defaults.baseURL = 'http://192.168.0.108:9991/';
// axios.defaults.baseURL = 'http://192.168.0.249:9991/';

// }
let ipAddress = axios.defaults.baseURL;
axios.interceptors.request.use((config) => {
    return config;
}, (error) => {
    return Promise.reject(error);
});

axios.interceptors.response.use((res) => {
    closeLoading();
    return Promise.resolve(res);
}, (error) => {
    closeLoading();
    let httpMessage = '';
    if (error.response) {
        if (error.response.data && error.response.data.message) {
            httpMessage = error.response.data.message;
        } else if (error.response.status == '404') {
            httpMessage = "未找到请求地址";
        }
    } else {
        httpMessage = '服务器处理异常'
    }
    redirect(error.response || {}, httpMessage);
    return Promise.reject(error.response);
});

function closeLoading() {
    if (loadingInstance) {
        loadingInstance.close();
    }
    if (loadingStatus) {
        loadingStatus = false;
        if (loadingInstance) {
            loadingInstance.close();
        }
    }
}
let _vue = null
const _Authorization = 'Authorization';

function init(vue) {
    _vue = vue;
}

function showLoading(loading) {
    if (!loading || loadingStatus) {
        return;
    }
    loadingInstance = Loading.service({
        target: '#loading-container',
        customClass: "el-loading",
        text: typeof loading == "string" ? loading : '正在处理.....',
        spinner: 'el-icon-loading',
        background: 'rgba(58, 61, 63, 0.32)'
    });
}

function getToken() {
    return store.getters.getToken();
}

/*
  url
  params请求后台的参数,如：{name:123,values:['a','b','c']}
  loading是否显示遮罩层,可以传入true.false.及提示文本
  config配置信息,如{timeout:3000,headers:{token:123}}
*/
function post(url, params, loading, config) {
    showLoading(loading);
    axios.defaults.headers[_Authorization] = getToken();
    return new Promise((resolve, reject) => {
        axios.post(url, params, config)
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err && err.data && err.data.message ? err.data.message : '服务器处理异常');
            })
            .catch((error) => {
                reject(error)
            })
    })
}

//=true异步请求时会显示遮罩层,=字符串，异步请求时遮罩层显示当前字符串
function get(url, param, loading, config) {
    showLoading(loading);
    axios.defaults.headers[_Authorization] = getToken();
    return new Promise((resolve, reject) => {
        axios.get(url, { params: param }, config)
            .then(response => {
                resolve(response.data)
            }, err => {
                reject(err)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

function redirect(responseText, message) {
    try {
        let responseData = typeof responseText == 'string' ? JSON.parse(responseText) : responseText;

        if ((Object.prototype.hasOwnProperty.call(responseData, "code") &&
                responseData.code == 401) ||
            (responseData.data && responseData.data.code == 401)) {
            closeLoading();
            toLogin();
        } else {
            Message.error({
                showClose: true,
                message: message,
                type: 'error'
            });
        }
    } catch (error) {
        console.log(error);
        Message.error({
            showClose: true,
            message: responseText,
            type: 'error'
        });
    }
}

function toLogin() {
    _vue.$router.push({ path: '/login', params: { r: Math.random() } });
}

export default { post, get, init, showLoading, closeLoading, ipAddress }
