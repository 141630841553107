<template>
    <div  class="menu">
        <div  class="leftWrapper" :class="{leftWrapperSq:isCollapse}" style="position: relative;">
            <div class="menuClick" v-if="!isCollapse" @click="isCollapse=!isCollapse"><img src="../assets/icon/sq.png" width="20px" /> </div>

            <el-dropdown class="" style="width: 100%;text-align: center;padding: 20px 0;margin:0; border-bottom:1px rgba(29,258,240,0.1) solid">

                <div class="el-dropdown-link">
                    <div style="margin-bottom:10px;"><img src="../assets/images/user.png" width="40px" /> </div>
                    <div>
                        你好，{{ userName }}
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </div>
                </div>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>
                        <el-link icon="fa fa-key" :underline="false" @click="changePassword">修改密码</el-link>
                    </el-dropdown-item>
                    <el-dropdown-item v-show="false">
                        <el-link icon="fa fa-key" @click="admin" :underline="false">后台管理</el-link>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-link icon="fa fa-sign-out" @click="logout" :underline="false">
                            退出</el-link>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <!-- <el-radio-group v-model="isCollapse" style="margin-bottom: 20px;">
                <el-radio-button :label="false">展开</el-radio-button>
                <el-radio-button :label="true">收起</el-radio-button>
            </el-radio-group> -->
            <div class="menuClick1" v-if="isCollapse" @click="isCollapse=!isCollapse">
                <div class="box">
                    <img src="../assets/icon/zk.png" width="20px" />
                </div>
            </div>
            <el-menu :router='true' :unique-opened="true" :default-active="defPath" class="el-menu-vertical-demo" text-color="#fff" active-text-color="#ffd04b" @open="handleOpen" @close="handleClose" :collapse="isCollapse">
<!--                <el-menu-item index="/Search" v-show="checkAuth('/Search','统计')">-->
<!--                    <img src="../assets/icon/zl.png" class="menuimg" alt="">-->
<!--                    <span slot="title">统计</span>-->
<!--                </el-menu-item>-->
<!--                <el-menu-item index="/AMap" v-show="checkAuth('/AMap','地图')">-->
<!--                    <img src="../assets/icon/gtkj.png" class="menuimg" alt="">-->
<!--                    <span slot="title">地图</span>-->
<!--                </el-menu-item>-->
                <el-submenu index="0" @click.native="totj" v-show="checkAuth('/ghk','规划项目库')">
                    <template slot="title">
                        <img src="../assets/icon/gh.png" class="menuimg" alt="">
                        <span>规划管理</span>
                    </template>
                    <el-submenu index="0-1" v-show="checkAuth('/ghk','规划项目库')">
                        <template slot="title">
                            <span>十四五规划</span>
                        </template>
                        <el-menu-item index="/Road/Project" v-show="checkAuth('/Road/Project','高速和普通国省道')">公路</el-menu-item>
                        <el-menu-item index="/Railway/Project" v-show="checkAuth('/Railway/Project','铁路')">铁路</el-menu-item>
                        <el-menu-item index="/Airport/Project" v-show="checkAuth('/Airport/Project','民航')">民航</el-menu-item>
                        <el-menu-item index="/Water/Project" v-show="checkAuth('/Water/Project','水运')">水运</el-menu-item>
                        <el-menu-item index="/Road/Bridge" v-show="checkAuth('/Road/Bridge','危桥库')">危桥库</el-menu-item>
                    </el-submenu>
<!--                    <el-menu-item index="/plantForm" v-show="checkAuth('/plantForm','国土空间')"  @click="alert()">国土空间</el-menu-item>-->
                    <el-submenu index="0-2" v-show="checkAuth('/zt','专题')">
                        <template slot="title">
                            <span>其他专项管理</span>
                        </template>
                        <el-menu-item index="/plantForm" v-show="checkAuth('/plantForm','国土空间专题')"  @click="alert()">国土空间专题</el-menu-item>
                        <el-menu-item index="5"   @click="alert1()">枢纽场站</el-menu-item>
                        <el-menu-item index="/countrySide" v-show="checkAuth('/countrySide','农村公路')">农村公路</el-menu-item>
                        <el-menu-item index="/confirmationLetter" v-show="checkAuth('/confirmationLetter','确认函上传')">确认函上传</el-menu-item>
                        <el-menu-item index="/aPicture" v-show="checkAuth('/aPicture','一张图')">一张图</el-menu-item>
                        <el-menu-item index="/statistics" v-show="checkAuth('/statistics','上传统计')">上传统计</el-menu-item>
                    </el-submenu>
<!--                    <el-menu-item index="">国道网线位规划</el-menu-item>-->
                </el-submenu>
                <el-submenu index="1" v-show="checkAuth('/qqgzjz','前期工作进展')">
                    <template slot="title">
                        <img src="../assets/icon/lc.png" class="menuimg" alt="">
                        <span>前期管理</span>
                    </template>
                    <el-menu-item index="/Road/Front" v-show="checkAuth('/Road/Front','高速和普通国省道')">公路</el-menu-item>
                    <el-menu-item index="/Water/Front" v-show="checkAuth('/Water/Front','水运')">水运</el-menu-item>
                    <el-submenu index="1-1" v-show="checkAuth('/Hinge/Front','枢纽场站')">
                        <template slot="title">
                            <!--                            <img src="../assets/icon/gh.png" class="menuimg" alt="">-->
                            <span>运输</span>
                        </template>
                        <el-menu-item index="/Hinge/Front" v-show="checkAuth('/Hinge/Front','枢纽场站')">枢纽场站</el-menu-item>
                    </el-submenu>
                </el-submenu>
                <!--                <el-submenu index="2" v-show="checkAuth('/jhk','计划管理')">-->
<!--                    <template slot="title">-->
<!--                        <img src="../assets/icon/jh.png" class="menuimg" alt="">-->
<!--                        <span>计划管理</span>-->
<!--                    </template>-->
<!--                    <el-menu-item index="/Road/planl" v-show="checkAuth('/Road/planl','高速和普通国省道')">高速和普通国省道</el-menu-item>-->
<!--                </el-submenu>-->
                <el-submenu index="2" v-show="checkAuth('/planFilling','填报系统')">
                    <template slot="title">
                        <img src="../assets/icon/jh.png" class="menuimg" alt="">
                        <span>计划管理</span>
                    </template>
                    <el-menu-item index="/Road/investment" v-show="checkAuth('/Road/investment','历年计划')">历年计划</el-menu-item>
                    <el-menu-item index="/Road/fileCenter" v-show="checkAuth('/Road/investment','历年计划')">文件中心</el-menu-item>
                    <el-menu-item index="/Road/dailyJob" v-show="checkAuth('/Road/investment','历年计划')">日常办公</el-menu-item>
                    <el-menu-item index="/Road/planRelease" v-show="checkAuth('/Road/investment','历年计划')">累计下达</el-menu-item>
                    <el-menu-item index="/Road/jhtb">计划填报</el-menu-item>
<!--                    <el-menu-item index="" @click="alert2()">计划审核</el-menu-item>-->
<!--                    <el-menu-item index="" @click="alert2()">计划下达</el-menu-item>-->
<!--                    <el-menu-item index="" @click="alert2()">计划执行</el-menu-item>-->

                    <!-- <el-menu-item index="/Road/planl" v-show="checkAuth('/Road/planl','高速和普通国省道')">计划下达</el-menu-item> -->
<!--                    <el-menu-item index="/plainFilling/heighRoad">高速和普通国省道</el-menu-item>-->
<!--                    <el-menu-item index="/plainFilling/waterRoad">水运</el-menu-item>-->
<!--                    <el-menu-item index="/plainFilling/zcxt">支持系统</el-menu-item>-->
<!--                    <el-menu-item index="/plainFilling/sggl">综合交通（疏港公路）</el-menu-item>-->
<!--                    <el-menu-item index="/plainFilling/sgtl">综合交通（疏港铁路）</el-menu-item>-->
<!--                    <el-menu-item index="/plainFilling/zhjt">综合交通（综合客运枢纽）</el-menu-item>-->
<!--                    <el-menu-item index="/plainFilling/hysn">综合交通（货运枢纽-物流园区）</el-menu-item>-->
<!--                    <el-menu-item index="/plainFilling/kyz">市县客运站</el-menu-item>-->
<!--                    <el-menu-item index="/plainFilling/hyxxh">行业信息化</el-menu-item>-->
<!--                    <el-menu-item index="/plainFilling/yjdb" v-show="checkAuth('/plainFilling/yjdb','以奖代补考核建设目标任务')">以奖代补考核建设目标任务</el-menu-item>-->
<!--                    <el-menu-item index="/plainFilling/confirmationLetter">计划申请及确认文件</el-menu-item>-->
<!--                    -->

                </el-submenu>
                <el-submenu index="3" v-show="checkAuth('/Search','统计')">
                    <template slot="title">
                        <img src="../assets/icon/zl.png" class="menuimg" alt="">
                        <span>统计管理</span>
                    </template>
                    <el-menu-item index="/Search">
                        <span slot="title">规划统计</span>
                    </el-menu-item>
<!--                    <el-menu-item index="3-2">-->
<!--                        <span slot="title">固投统计</span>-->
<!--                    </el-menu-item>-->
<!--                    <el-menu-item index="3-3">-->
<!--                        <span slot="title">其他统计</span>-->
<!--                    </el-menu-item>-->
                </el-submenu>
<!--                <el-submenu index="5" @click="alert1()">-->
<!--                    <template slot="title">-->
<!--                        <img src="../assets/icon/sncz.png" class="menuimg" alt="">-->
<!--                        <span>枢纽场站</span>-->
<!--                    </template>-->
<!--                </el-submenu>-->
                <el-submenu index="6" v-show="checkAuth('/AMap','地图')">
                    <template slot="title">
                        <img src="../assets/icon/dq.png" class="menuimg" alt="">
                        <span>数字底座</span>
                    </template>
                    <el-menu-item index="/AMap" v-show="checkAuth('/AMap','地图')">交通信息底图</el-menu-item>
                    <el-menu-item index="/flow/proInfo" v-show="checkAuth('/ghk','规划项目库')">建设项目信息</el-menu-item>
                </el-submenu>
                <!-- 第二次菜单 -->
                <!-- <el-menu-item index="/" v-show="checkAuth('/','总览')">
                    <img src="../assets/icon/zl.png" class="menuimg" alt="">
                    <span slot="title">总览</span>
                </el-menu-item>
                <el-menu-item index="/plantForm" v-show="checkAuth('/plantForm','国土空间专题')" @click="alert()">
                    <img src="../assets/icon/gtkj.png" class="menuimg" alt="">
                    <span slot="title">国土空间专题</span>
                </el-menu-item>
                <el-submenu index="0" v-show="checkAuth('','综合查询')">
                    <template slot="title">
                        <img src="../assets/icon/zhcx.png" class="menuimg" alt="">
                        <span>综合查询</span>
                    </template>
                    <el-menu-item index="/Search?active=0" v-show="checkAuth('/Search?active=0','统计')">统计</el-menu-item>
                    <el-menu-item index="/Query?active=1" v-show="checkAuth('/Query?active=1','查询')">查询</el-menu-item>
                    <el-menu-item index="/AMap" v-show="checkAuth('/Map?active=2','地图')">地图</el-menu-item>
                </el-submenu>
                <el-menu-item index="/Assets" v-show="checkAuth('/Assets','地理信息图层集')">
                    <img src="../assets/icon/dq.png" class="menuimg" alt="">
                    <span slot="title">地理信息图层集</span>
                </el-menu-item>
                <el-submenu index="1" v-show="checkAuth('','高速和普通国省道')">
                    <template slot="title">
                        <img src="../assets/icon/gsgl.png" class="menuimg" alt="">
                        <span>高速和普通国省道</span>
                    </template>
                    <el-menu-item index="/Road/Project" v-show="checkAuth('/Road/Project','项目库')">项目库</el-menu-item>
                    <el-menu-item index="/Road/Bridge" v-show="checkAuth('/Road/Bridge','危桥库')">危桥库</el-menu-item>
                    <el-submenu index="1-2" v-show="checkAuth('','年度计划生成')">
                        <template slot="title">年度计划生成</template>
                        <el-menu-item index="1-2-1" v-show="checkAuth('','滚动调整')">滚动调整</el-menu-item>
                        <el-menu-item index="1-2-2" v-show="checkAuth('','申报')">申报</el-menu-item>
                        <el-menu-item index="1-2-3" v-show="checkAuth('','审核')">审核</el-menu-item>
                        <el-menu-item index="1-2-4" v-show="checkAuth('','下达')">下达</el-menu-item>
                        <el-menu-item index="1-2-5" v-show="checkAuth('','执行')">执行</el-menu-item>
                        <el-menu-item index="1-2-6" v-show="checkAuth('','考核评价')">考核评价</el-menu-item>
                    </el-submenu>
                    <el-menu-item index="1-3" v-show="checkAuth('','投资计划预警')">投资计划预警</el-menu-item>
                    <el-menu-item index="1-4" v-show="checkAuth('','国土空间规划衔接')">国土空间规划衔接</el-menu-item>
                    <el-menu-item index="1-5" v-show="checkAuth('','项目现状')">项目现状</el-menu-item>
                    <el-menu-item index="/Road/Front" v-show="checkAuth('/Road/Front','前期工作进展')">前期工作进展</el-menu-item>
                    <el-menu-item index="1-7" v-show="checkAuth('','养护库比对')">养护库比对</el-menu-item>
                    <el-menu-item index="1-8" v-show="checkAuth('','绩效考核')">绩效考核</el-menu-item>
                    <el-menu-item index="1-9" v-show="checkAuth('','上报交通部')">上报交通部</el-menu-item>
                    <el-menu-item index="1-10" v-show="checkAuth('','建设实施进度')">建设实施进度</el-menu-item>
                </el-submenu>
                <el-submenu index="2" v-show="checkAuth('','农村公路')">
                    <template slot="title">
                        <img src="../assets/icon/ncgl.png" class="menuimg" alt="">
                        <span>农村公路</span>
                    </template>
                    <el-menu-item index="2-1" v-show="checkAuth('','项目库')">项目库</el-menu-item>
                    <el-submenu index="2-2" v-show="checkAuth('','年度计划生成')">
                        <template slot="title">年度计划生成</template>
                        <el-menu-item index="2-2-1" v-show="checkAuth('','滚动调整')">滚动调整</el-menu-item>
                        <el-menu-item index="2-2-2" v-show="checkAuth('','申报')">申报</el-menu-item>
                        <el-menu-item index="2-2-3" v-show="checkAuth('','审核')">审核</el-menu-item>
                        <el-menu-item index="2-2-4" v-show="checkAuth('','下达')">下达</el-menu-item>
                        <el-menu-item index="2-2-5" v-show="checkAuth('','执行')">执行</el-menu-item>
                        <el-menu-item index="2-2-6" v-show="checkAuth('','考核评价')">考核评价</el-menu-item>
                    </el-submenu>
                    <el-menu-item index="2-3" v-show="checkAuth('','投资计划预警')">投资计划预警</el-menu-item>
                    <el-menu-item index="2-4" v-show="checkAuth('','国土空间规划衔接')">国土空间规划衔接</el-menu-item>
                    <el-menu-item index="2-5" v-show="checkAuth('','项目现状')">项目现状</el-menu-item>
                    <el-menu-item index="2-6" v-show="checkAuth('','前期工作进展')">前期工作进展</el-menu-item>
                    <el-menu-item index="2-7" v-show="checkAuth('','养护库比对')">养护库比对</el-menu-item>
                    <el-menu-item index="2-8" v-show="checkAuth('','绩效考核')">绩效考核</el-menu-item>
                    <el-menu-item index="2-9" v-show="checkAuth('','上报交通部')">上报交通部</el-menu-item>
                    <el-menu-item index="2-10" v-show="checkAuth('','建设实施进度')">建设实施进度</el-menu-item>
                </el-submenu>
                <el-submenu index="3" v-show="checkAuth('','水运')">
                    <template slot="title">
                        <img src="../assets/icon/sy.png" class="menuimg" alt="">
                        <span>水运</span>
                    </template>
                    <el-menu-item index="/Water/Project" v-show="checkAuth('/Water/Project','项目库')">项目库</el-menu-item>
                    <el-submenu index="3-2" v-show="checkAuth('','年度计划生成')">
                        <template slot="title">年度计划生成</template>
                        <el-menu-item index="3-2-1" v-show="checkAuth('','滚动调整')">滚动调整</el-menu-item>
                        <el-menu-item index="3-2-2" v-show="checkAuth('','申报')">申报</el-menu-item>
                        <el-menu-item index="3-2-3" v-show="checkAuth('','审核')">审核</el-menu-item>
                        <el-menu-item index="3-2-4" v-show="checkAuth('','下达')">下达</el-menu-item>
                        <el-menu-item index="3-2-5" v-show="checkAuth('','执行')">执行</el-menu-item>
                        <el-menu-item index="3-2-6" v-show="checkAuth('','考核评价')">考核评价</el-menu-item>
                    </el-submenu>
                    <el-menu-item index="3-3" v-show="checkAuth('','投资计划预警')">投资计划预警</el-menu-item>
                    <el-menu-item index="/Water/Front" v-show="checkAuth('/Water/Front','前期工作进展')">前期工作进展</el-menu-item>
                    <el-menu-item index="3-8" v-show="checkAuth('','绩效考核')">绩效考核</el-menu-item>
                    <el-menu-item index="3-10" v-show="checkAuth('','建设实施进度')">建设实施进度</el-menu-item>
                </el-submenu>
                <el-submenu index="4" v-show="checkAuth('','枢纽场站')">
                    <template slot="title">
                        <img src="../assets/icon/sn.png" class="menuimg" alt="">
                        <span>枢纽场站</span>
                    </template>
                    <el-menu-item index="/Hinge/Project" v-show="checkAuth('/Hinge/Project','项目库')">项目库</el-menu-item>
                    <el-submenu index="4-2" v-show="checkAuth('','年度计划生成')">
                        <template slot="title">年度计划生成</template>
                        <el-menu-item index="4-2-1" v-show="checkAuth('','滚动调整')">滚动调整</el-menu-item>
                        <el-menu-item index="4-2-2" v-show="checkAuth('','申报')">申报</el-menu-item>
                        <el-menu-item index="4-2-3" v-show="checkAuth('','审核')">审核</el-menu-item>
                        <el-menu-item index="4-2-4" v-show="checkAuth('','下达')">下达</el-menu-item>
                        <el-menu-item index="4-2-5" v-show="checkAuth('','执行')">执行</el-menu-item>
                        <el-menu-item index="4-2-6" v-show="checkAuth('','考核评价')">考核评价</el-menu-item>
                    </el-submenu>
                    <el-menu-item index="4-3" v-show="checkAuth('','投资计划预警')">投资计划预警</el-menu-item>
                    <el-menu-item index="/Hinge/Front" v-show="checkAuth('/Hinge/Front','前期工作进展')">前期工作进展</el-menu-item>
                    <el-menu-item index="4-8" v-show="checkAuth('','绩效考核')">绩效考核</el-menu-item>
                    <el-menu-item index="4-10" v-show="checkAuth('','建设实施进度')">建设实施进度</el-menu-item>
                </el-submenu>
                <el-submenu index="5" v-show="checkAuth('','铁路')">
                    <template slot="title">
                        <img src="../assets/icon/tl.png" class="menuimg" alt="">
                        <span>铁路</span>
                    </template>
                    <el-menu-item index="5-1" v-show="checkAuth('','项目库')">项目库</el-menu-item>
                    <el-submenu index="5-2" v-show="checkAuth('','年度计划生成')">
                        <template slot="title">年度计划生成</template>
                        <el-menu-item index="5-2-1" v-show="checkAuth('','滚动调整')">滚动调整</el-menu-item>
                        <el-menu-item index="5-2-2" v-show="checkAuth('','申报')">申报</el-menu-item>
                        <el-menu-item index="5-2-3" v-show="checkAuth('','审核')">审核</el-menu-item>
                        <el-menu-item index="5-2-4" v-show="checkAuth('','下达')">下达</el-menu-item>
                        <el-menu-item index="5-2-5" v-show="checkAuth('','执行')">执行</el-menu-item>
                        <el-menu-item index="5-2-6" v-show="checkAuth('','考核评价')">考核评价</el-menu-item>
                    </el-submenu>
                    <el-menu-item index="5-3" v-show="checkAuth('','投资计划预警')">投资计划预警</el-menu-item>
                    <el-menu-item index="5-6" v-show="checkAuth('','前期工作进展')">前期工作进展</el-menu-item>
                    <el-menu-item index="5-8" v-show="checkAuth('','绩效考核')">绩效考核</el-menu-item>
                    <el-menu-item index="5-10" v-show="checkAuth('','建设实施进度')">建设实施进度</el-menu-item>
                </el-submenu>
                <el-submenu index="6" v-show="checkAuth('','民航')">
                    <template slot="title">
                        <img src="../assets/icon/mh.png" class="menuimg" alt="">
                        <span>民航</span>
                    </template>
                    <el-menu-item index="6-1" v-show="checkAuth('','项目库')">项目库</el-menu-item>
                    <el-submenu index="6-2" v-show="checkAuth('','年度计划生成')">
                        <template slot="title">年度计划生成</template>
                        <el-menu-item index="6-2-1" v-show="checkAuth('','滚动调整')">滚动调整</el-menu-item>
                        <el-menu-item index="6-2-2" v-show="checkAuth('','申报')">申报</el-menu-item>
                        <el-menu-item index="6-2-3" v-show="checkAuth('','审核')">审核</el-menu-item>
                        <el-menu-item index="6-2-4" v-show="checkAuth('','下达')">下达</el-menu-item>
                        <el-menu-item index="6-2-5" v-show="checkAuth('','执行')">执行</el-menu-item>
                        <el-menu-item index="6-2-6" v-show="checkAuth('','考核评价')">考核评价</el-menu-item>
                    </el-submenu>
                    <el-menu-item index="6-3" v-show="checkAuth('','投资计划预警')">投资计划预警</el-menu-item>
                    <el-menu-item index="6-6" v-show="checkAuth('','前期工作进展')">前期工作进展</el-menu-item>
                    <el-menu-item index="6-8" v-show="checkAuth('','绩效考核')">绩效考核</el-menu-item>
                    <el-menu-item index="6-10" v-show="checkAuth('','建设实施进度')">建设实施进度</el-menu-item>
                </el-submenu> -->

                <!-- 第二次菜单 -->

                <!-- <el-submenu index="1">
                    <template slot="title">
                        <i class="el-icon-bangzhu"></i>
                        <span>规划管理</span>
                    </template>
                    <el-menu-item-group title="公路">
                        <el-menu-item index="/Plan/Category">规划分类</el-menu-item>
                        <el-menu-item index="/Plan/Index">规划编制</el-menu-item>
                        <el-menu-item index="/Plan/Verify">规划审核</el-menu-item>
                        <el-menu-item index="/Plan/Plan">规划库</el-menu-item>
                    </el-menu-item-group>
                    <el-menu-item-group title="水路">
                        <el-menu-item index="/Plan/Plan1">规划库</el-menu-item>
                    </el-menu-item-group>
                    <el-menu-item-group title="枢纽场站">
                        <el-menu-item index="/Plan/Plan2">规划库</el-menu-item>
                    </el-menu-item-group>
                </el-submenu>
                <el-submenu index="2">
                    <template slot="title">
                        <i class="el-icon-s-help"></i>
                        <span>计划管理</span>
                    </template>
                    <el-menu-item-group title="公路">
                        <el-menu-item index="/Release/Proposal">建议计划</el-menu-item>
                        <el-menu-item index="/Release/Release">计划下达</el-menu-item>
                    </el-menu-item-group>
                    <el-menu-item-group title="水路">
                        <el-menu-item index="/Release/Proposal1">建议计划</el-menu-item>
                        <el-menu-item index="/Release/Release1">计划下达</el-menu-item>
                    </el-menu-item-group>
                    <el-menu-item-group title="枢纽场站">
                        <el-menu-item index="/Release/Proposal2">建议计划</el-menu-item>
                        <el-menu-item index="/Release/Release2">计划下达</el-menu-item>
                    </el-menu-item-group>
                </el-submenu>
                <el-submenu index="3">
                    <template slot="title">
                        <i class="el-icon-share"></i>
                        <span>前期工作进展</span>
                    </template>
                    <el-menu-item-group title="公路">
                        <el-menu-item index="/Early/Index">前期工作进展上报</el-menu-item>
                        <el-menu-item index="/Early/VerifyC">市级审核</el-menu-item>
                        <el-menu-item index="/Early/VerifyP">省级审核</el-menu-item>
                    </el-menu-item-group>
                    <el-menu-item-group title="水路">
                        <el-menu-item index="/Early/Index1">前期工作进展上报</el-menu-item>
                        <el-menu-item index="/Early/VerifyC1">市级审核</el-menu-item>
                        <el-menu-item index="/Early/VerifyP1">省级审核</el-menu-item>
                    </el-menu-item-group>
                    <el-menu-item-group title="枢纽场站">
                        <el-menu-item index="/Early/Index2">前期工作进展上报</el-menu-item>
                        <el-menu-item index="/Early/VerifyC2">市级审核</el-menu-item>
                        <el-menu-item index="/Early/VerifyP2">省级审核</el-menu-item>
                    </el-menu-item-group>
                </el-submenu>
                <el-submenu index="4">
                    <template slot="title">
                        <i class="el-icon-s-marketing"></i>
                        <span>建设进度</span>
                    </template>
                    <el-menu-item-group title="公路">
                        <el-menu-item index="/Build/Index">前期工作进展上报</el-menu-item>
                        <el-menu-item index="/Build/VerifyC">市级审核</el-menu-item>
                        <el-menu-item index="/Build/VerifyP">省级审核</el-menu-item>
                    </el-menu-item-group>
                    <el-menu-item-group title="水路">
                        <el-menu-item index="/Build/Index1">前期工作进展上报</el-menu-item>
                        <el-menu-item index="/Build/VerifyC1">市级审核</el-menu-item>
                        <el-menu-item index="/Build/VerifyP1">省级审核</el-menu-item>
                    </el-menu-item-group>
                    <el-menu-item-group title="枢纽场站">
                        <el-menu-item index="/Build/Index2">前期工作进展上报</el-menu-item>
                        <el-menu-item index="/Build/VerifyC2">市级审核</el-menu-item>
                        <el-menu-item index="/Build/VerifyP2">省级审核</el-menu-item>
                    </el-menu-item-group>
                </el-submenu>
                <el-submenu index="5">
                    <template slot="title">
                        <i class="el-icon-coin"></i>
                        <span>投资完成情况</span>
                    </template>
                    <el-menu-item-group title="公路">
                        <el-menu-item index="/Invest/Index">前期工作进展上报</el-menu-item>
                        <el-menu-item index="/Invest/VerifyC">市级审核</el-menu-item>
                        <el-menu-item index="/Invest/VerifyP">省级审核</el-menu-item>
                    </el-menu-item-group>
                    <el-menu-item-group title="水路">
                        <el-menu-item index="/Invest/Index1">前期工作进展上报</el-menu-item>
                        <el-menu-item index="/Invest/VerifyC1">市级审核</el-menu-item>
                        <el-menu-item index="/Invest/VerifyP1">省级审核</el-menu-item>
                    </el-menu-item-group>
                    <el-menu-item-group title="枢纽场站">
                        <el-menu-item index="/Invest/Index2">前期工作进展上报</el-menu-item>
                        <el-menu-item index="/Invest/VerifyC2">市级审核</el-menu-item>
                        <el-menu-item index="/Invest/VerifyP2">省级审核</el-menu-item>
                    </el-menu-item-group>
                </el-submenu>
                <el-submenu index="6">
                    <template slot="title">
                        <i class="el-icon-s-opportunity"></i>
                        <span>项目预警</span>
                    </template>
                    <el-menu-item index="/Warning/Highway">公路</el-menu-item>
                    <el-menu-item index="/Warning/Waterway">水路</el-menu-item>
                    <el-menu-item index="/Warning/Hinge">枢纽场站</el-menu-item>
                </el-submenu>
                <el-submenu index="7">
                    <template slot="title">
                        <i class="el-icon-mouse"></i>
                        <span>项目考核</span>
                    </template>
                    <el-menu-item index="/Assess/Highway">公路</el-menu-item>
                    <el-menu-item index="/Assess/Waterway">水路</el-menu-item>
                    <el-menu-item index="/Assess/Hinge">枢纽场站</el-menu-item>
                </el-submenu>
                <el-submenu index="8">
                    <template slot="title">
                        <i class="el-icon-document-copy"></i>
                        <span>统计分析</span>
                    </template>
                    <el-menu-item index="/Statistics/Highway">公路</el-menu-item>
                    <el-menu-item index="/Statistics/Waterway">水路</el-menu-item>
                    <el-menu-item index="/Statistics/Hinge">枢纽场站</el-menu-item>
                </el-submenu> -->
                <!-- <el-menu-item index="/Project">
                    <i class="el-icon-document"></i>
                    <span slot="title">导航三</span>
                </el-menu-item> -->

            </el-menu>
        </div>
        <div class="containers" :class="{containersSq:isCollapse}" style="position: relative;">
            <keep-alive>
                <router-view />
            </keep-alive>
        </div>

        <el-dialog title="修改密码" :visible.sync="passwordVisble" class="mapdialog dialog-lg" width="40%">
            <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="账户" prop="age">
                    <el-input v-model="name" disabled></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="pass">
                    <el-input type="password" v-model="ruleForm.password" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="checkPass">
                    <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name: "index",
    components: {},
    mounted() {
        console.log(this.$route);
        if (this.$route.path == '/Search'){
            this.showNav = false
        }else{
            this.showNav = true
        }
        this.userName = this.$store.getters.getUserInfo().userName;
        this.getPathFunIndex();
        // console.log(this.userName);
        if (this.userName) {
            this.getMenuAuth();
            this.http
                .post("/api/Sys_User/getCurrentUserInfo", {})
                .then((res) => {
                    this.name = res.data.userName;
                    this.isAdmin = res.data.roleName == '超级管理员' || res.data.isMain  == 1 ? true : false
                });
        }
    },
    data() {
        var validatePass = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入密码"));
            } else {
                if (this.ruleForm.checkPass !== "") {
                    this.$refs.ruleForm.validateField("checkPass");
                }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请再次输入密码"));
            } else if (value !== this.ruleForm.password) {
                callback(new Error("两次输入密码不一致!"));
            } else {
                callback();
            }
        };
        return {
            showNav: true,
            defPath: '/Search',
            isAdmin:false,
            ruleForm: {
                password: "",
                checkPass: "",
                userName: "",
            },
            rules: {
                password: [{ validator: validatePass, trigger: "blur" }],
                checkPass: [{ validator: validatePass2, trigger: "blur" }],
            },
            passwordVisble: false,
            auths: [],
            userName: "",
            name: "",
            menuIndex: 0, //菜单序号
            isCollapse: false,
        };
    },
    methods: {
        totj(item){
            console.log(item)
        },
        submitForm(formName) {
            var that = this;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    var params = JSON.parse(JSON.stringify(this.ruleForm));
                    params.userName = this.userName;
                    delete params.checkPass;
                    that.http
                        .post(
                            "/api/Sys_User/modifyUserPwd?password=" +
                                params.password +
                                "&userName=" +
                                that.name,
                            params
                        )
                        .then((res) => {
                            if (res.status) {
                                this.$message.success(res.message);
                                this.logout();
                            } else {
                                this.$message.error(res.message);
                            }
                        });
                } else {
                    // console.log("error submit!!");
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        changePassword() {
            this.passwordVisble = true;
            // console.log(1);
        },
        alert() {
            window.open("/#/plantForm");
        },
        alert1() {
            window.open("http://jhglcz.hljzztech.com/");
        },
        alert2() {
            this.$message.warning('暂未开放')
        },
        getMenuAuth() {
            this.http.post("/api/menu/getTreeMenu ", {}).then((r) => {
                this.auths = r;
                sessionStorage.setItem("auths", JSON.stringify(r));
            });
        },
        checkAuth(path, name) {
            // console.log(path,name);
            if (this.auths.length <= 0) return false;
            var status = this.auths.some(
                (item) =>
                    item.url == path && item.name == name && item.enable == 1
            );
            return status;
        },
        logout() {
            this.$store.commit("clearUserInfo", "");
            window.location.href = "/";
        },
        admin() {
            window.open("http://ghgl.hljzztech.com/");
        },
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
            // if (!this.isAdmin){
            //     return false
            // }
            // if (key == 0){
            //     this.$router.push('/Home/PlanIndex')
            // }
            // if (key == 1){
            //     this.$router.push('/Home/EarlyIndex')
            // }
            // if (key == 2){
            //     this.$router.push('/Home/JhIndex')
            // }
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        },
        handleSizeChange1(val) {
            this.pageSize1 = val;
            this.getCompanyList()
        },
        handleCurrentChange1(val) {
            this.currentPage1 = val;
            this.getCompanyList()

        },
        getPathFunIndex() {
            console.log(this.$route.fullPath, 123);
            this.defPath = this.$route.fullPath;
            this.isCollapse = false;
            // if (this.$route.path != "/Search" && this.defPath != "/Search") {
            //     this.isCollapse = true;
            // } else {
            //     this.isCollapse = false;
            // }
        },
    },
    watch: {
        $route: "getPathFunIndex",
    },
};
</script>
<style lang="less">
.el-menu-item,
.el-submenu__title {
    height: 46px !important;
    line-height: 46px !important;
}
.el-submenu .el-menu-item {
    height: 40px !important;
    line-height: 40px !important;
}

.el-menu {
    height: 100%;
    border-right: 0 !important;
    background-color: none !important;
    background-image: linear-gradient(#495078, #384065);
}

.el-submenu__title i,
.el-menu-item i {
    color: #fff !important;
}

.menu {
    display: flex;
    height: 100%;
    .leftWrapper {
        width: 200px;
        overflow: hidden;
        height: 100%;
        background: #3b4268;
        flex-shrink: 0;
    }
    .leftWrapperSq {
        width: 64px;
        .el-dropdown {
            display: none;
        }
    }
    .containers {
        width: calc(100% - 200px);
    }
    .containersSq {
        width: calc(100% - 64px);
    }
    .el-menu-item-group__title {
        padding-left: 49px !important;
        color: #ddd;
        background-color: #455582;
        font-size: 13px;
        font-weight: bold;
    }
}

.el-submenu__title:focus,
.el-submenu__title:hover {
    color: #fff !important;
    background-color: #1d9ef0 !important;
}

.el-menu-item.is-active {
    color: #fff !important;
    background-color: #1d9ef0 !important;
}

.el-submenu .el-menu .el-menu-item {
    padding-left: 49px !important;
}
.el-submenu .el-menu .el-submenu .el-submenu__title {
    padding-left: 49px !important;
}

.el-menu-item:focus,
.el-menu-item:hover {
    color: #fff !important;
    background-color: #1d9ef0 !important;
}
.el-submenu .el-menu .el-menu-item:focus,
.el-submenu .el-menu .el-menu-item:hover,
.el-submenu .el-menu .el-menu-item.is-active {
    background-color: #4376ba !important;
}

.el-menu-vertical-demo {
    height: calc(100% - 120px);
    overflow: auto;

    &::-webkit-scrollbar {
        height: 0px;
        width: 0px;
        z-index: 9999;
    }
}
.menuimg {
    width: 18px;
    height: 18px;
    margin-right: 5px;
    padding: 0 3px;
}
.menuClick {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    z-index: 99;
    padding: 0;
    width: 36px;
    height: 36px;
    background: rgba(64, 158, 255, 1);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    opacity: 1;
    border-radius: 0px 0px 0px 30px;
    text-align: center;
    line-height: 30px;
    img {
        position: relative;
        right: -3px;
    }
}
.menuClick1 {
    display: flex;
    justify-content: flex-end;
    background: #495078;
    .box {
        padding: 0px;
        border-radius: 7px;
        text-align: center;
        cursor: pointer;
        width: 36px;
        height: 36px;
        background: rgba(64, 158, 255, 1);
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        opacity: 1;
        border-radius: 0px 0px 0px 30px;
        text-align: center;
        line-height: 30px;
        img {
            position: relative;
            right: -3px;
        }
    }
}
</style>
