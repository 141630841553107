import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import store from '../store/index'
// import store from '@/store'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Search',
        component: Index,
        redirect: '/Search',
    meta: {
        anonymous: true
    },
        children: [{
                path: '/Search',
                name: 'Search',
                component: () =>
                    import ('../views/Home/Index.vue'),
            meta: {
                anonymous: true
            }

        },
            {
                path: '/Query',
                name: 'Query',
                component: () =>
                    import ('../views/Query/Index.vue')
            },
            {
                path: '/Map',
                name: 'Map',
                component: () =>
                    import ('../views/Map/Index.vue'),
            },
            {
                path: '/AMap',
                name: 'AMap',
                component: () =>
                    import ('../views/AMap/Index1.vue'),
            },
            {
                path: '/Project',
                name: 'Project',
                component: () =>
                    import ('../views/Project/Index.vue'),
            },
            {
                path: '/Assets',
                name: 'Assets',
                component: () =>
                    import ('../views/Assets/Index.vue'),
            },
            {
                path: '/Assets2',
                name: 'Assets2',
                component: () =>
                    import ('../views/Assets/Index2.vue'),
            },
            {
                path: "/Road/Project",
                name: "Road_Project",
                component: () =>
                    import ('../views/Road/Project.vue')
            },
            {
                path: "/Road/Front",
                name: "Road_Front",
                component: () =>
                    import ('../views/Road/Front.vue')
            },
            {
                path: "/Road/Front1",
                name: "Road_Front1",
                component: () =>
                    import ('../views/Road/Front1.vue')
            },
            {
                path: "/Road/planl",
                name: "Road_planl",
                component: () =>
                    import ('../views/Road/planl.vue')
            },
            {
                path: "/Road/jhtb",
                name: "jhtb",
                component: () =>
                    import ('../views/Road/jhtb.vue')
            },
            {
                path: "/Road/investment",
                name: "investment",
                component: () =>
                    import ('../views/Road/investment.vue')
            },
            {
                path: "/Road/fileCenter",
                name: "fileCenter",
                component: () =>
                    import ('../views/Road/fileCenter.vue')
            },
            {
                path: "/Road/dailyJob",
                name: "dailyJob",
                component: () =>
                    import ('../views/Road/dailyJob.vue')
            },
            {
                path: "/Road/planRelease",
                name: "planRelease",
                component: () =>
                    import ('../views/Road/planRelease.vue')
            },
            {
                path: "/Road/Bridge",
                name: "Road_Bridge",
                component: () =>
                    import ('../views/Road/Bridge.vue')
            },
            {
                path: "/Water/Project",
                name: "Water_Project",
                component: () =>
                    import ('../views/Water/Project.vue')
            },
            {
                path: "/Water/Front",
                name: "Water_Front",
                component: () =>
                    import ('../views/Water/Front.vue')
            },
            {
                path: "/Hinge/Project",
                name: "Hinge_Project",
                component: () =>
                    import ('../views/Hinge/Project.vue')
            }, {
                path: "/Airport/Project",
                name: "Airport_Project",
                component: () =>
                    import ('../views/Airport/Project.vue')
            }, {
                path: "/Railway/Project",
                name: "Railway_Project",
                component: () =>
                    import ('../views/Railway/Project.vue')
            },
            {
                path: "/Hinge/Front",
                name: "Hinge_Front",
                component: () =>
                    import ('../views/Hinge/Front.vue')
            },
            {
                path: "/demo",
                name: "demo",
                component: () =>
                    import ('../views/demo.vue')
            },
            {
                path: "/countrySide",
                name: "countrySide",
                component: () =>
                    import ('../views/countrySide.vue'),
            },
            {
                path: "/aPicture",
                name: "aPicture",
                component: () =>
                    import ('../views/special/aPicture.vue'),
            },
            {
                path: "/statistics",
                name: "statistics",
                component: () =>
                    import ('../views/special/statistics.vue'),
            },
            {
                path: "/confirmationLetter",
                name: "confirmationLetter",
                component: () =>
                    import ('../views/confirmationLetter.vue'),
            },
            {
                path: "/plainFilling/sncz",
                name: "sncz",
                component: () =>
                    import ('../views/plainFilling/sncz.vue'),
            },
            {
                path: "/plainFilling/confirmationLetter",
                name: "plainFilling_confirmationLetter",
                component: () =>
                    import ('../views/plainFilling/confirmationLetter.vue'),
            },
            {
                path: "/plainFilling/bridgeConfirmationLetter",
                name: "bridgeConfirmationLetter",
                component: () =>
                    import ('../views/plainFilling/bridgeConfirmationLetter.vue'),
            },
            {
                path: "/plainFilling/heighRoad",
                name: "heighRoad",
                component: () =>
                    import ('../views/plainFilling/heighRoad.vue')
            },
            {
                path: "/plainFilling/disaster",
                name: "disaster",
                component: () =>
                    import ('../views/plainFilling/disaster.vue')
            },
            {
                path: "/plainFilling/waterRoad",
                name: "waterRoad",
                component: () =>
                    import ('../views/plainFilling/waterRoad.vue')
            },
            {
                path: "/plainFilling/hysn",
                name: "hysn",
                component: () =>
                    import ('../views/plainFilling/hysn.vue')
            },
            {
                path: "/plainFilling/hyxxh",
                name: "hyxxh",
                component: () =>
                    import ('../views/plainFilling/hyxxh.vue')
            },
            {
                path: "/plainFilling/kyz",
                name: "kyz",
                component: () =>
                    import ('../views/plainFilling/kyz.vue')
            },
            {
                path: "/plainFilling/sggl",
                name: "sggl",
                component: () =>
                    import ('../views/plainFilling/sggl.vue')
            },
            {
                path: "/plainFilling/sgtl",
                name: "sgtl",
                component: () =>
                    import ('../views/plainFilling/sgtl.vue')
            },
            {
                path: "/plainFilling/yjdb",
                name: "sgtl",
                component: () =>
                    import ('../views/plainFilling/yjdb.vue')
            },
            {
                path: "/plainFilling/zcxt",
                name: "zcxt",
                component: () =>
                    import ('../views/plainFilling/zcxt.vue')
            },
            {
                path: "/plainFilling/zhjt",
                name: "zhjt",
                component: () =>
                    import ('../views/plainFilling/zhjt.vue')
            },
            {
                path: "/plainFilling/bridge",
                name: "bridge",
                component: () =>
                    import ('../views/plainFilling/bridge.vue')
            },
            {
                path: "/flow/proInfo",
                name: "proInfo",
                component: () =>
                    import ('../views/flow/proInfo.vue')
            },
            {
                path: "/Home/PlanIndex",
                name: "PlanIndex",
                component: () =>
                    import ('../views/Home/PlanIndex.vue')
            },
            {
                path: "/Home/EarlyIndex",
                name: "EarlyIndex",
                component: () =>
                    import ('../views/Home/EarlyIndex.vue')
            },
            {
                path: "/Home/JhIndex",
                name: "JhIndex",
                component: () =>
                    import ('../views/Home/JhIndex.vue')
            },
        ]
    },
    {
        path: "/plantForm",
        name: "plantForm",
        component: () =>
            import ('../views/plantForm.vue'),
        meta: {
            anonymous: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () =>
            import ('../views/Login.vue'),
        meta: {
            anonymous: true
        }
    },
    {
        path: '/register',
        name: 'register',
        component: () =>
            import ('../views/Register.vue'),
        meta: {
            anonymous: true
        }
    }
]

const router = new VueRouter({
    history: 'true',
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
})
var auths = []
if (JSON.parse(sessionStorage.getItem('auths'))) {
    auths = JSON.parse(sessionStorage.getItem('auths'))
}
const whiteList = ['/login', '/404', '/register', '/plainFilling/bridge','/plainFilling/waterRoad','/plainFilling/disaster','/plainFilling/sncz','/plainFilling/bridgeConfirmationLetter','/Home','/Road/planRelease','/Home/PlanIndex','/Home/JhIndex','/Home/EarlyIndex', '/Search','/Road/jhtb','/Road/fileCenter','/Road/dailyJob','/flow/proInfo', '/index', '/AMap', '/plantForm', '/Project', '/Assets2', '/plainFilling/confirmationLetter']
router.beforeEach((to, from, next) => {
    console.log(to)
        // console.log(from)
        // return false;
        // console.log(this.$route, 123);
        // console.log(to.fullPath == '/plantForm');
    if (to.fullPath == '/plantForm' && from.name) return false;
    var status = true
    if (auths.length != 0) {
        status = auths.some((item) => item.url == to.fullPath && item.enable == 1 || whiteList.indexOf(to.path) !== -1)
        if (status) return next()
        if (!status) return false
    }
    if (to.matched.length == 0) return next({ path: '/404' });
    //2020.06.03增加路由切换时加载提示
    store.dispatch("onLoading", true);
    if ((Object.prototype.hasOwnProperty.call(to, 'meta') && to.meta.anonymous) || store.getters.isLogin()) {
        return next();
    }
    //query产生一个随机数在 login->home->login执行不了
    next({ path: '/login', query: { redirect: Math.random() } });



})

//2020.06.03增加路由切换时加载提示
router.afterEach((to, from) => {
    // console.log(to);
    // console.log(from);
    store.dispatch("onLoading", false);
})
router.onError((error) => {
    const pattern = /Loading chunk (\d)+ failed/g;
    const isChunkLoadFailed = error.message.match(pattern);
    const targetPath = router.history.pending.fullPath;
    // console.log(error.message);
    // console.log(targetPath);
    if (isChunkLoadFailed) {
        window.location.replace(window.location.href);
        //  router.replace(targetPath);
    }
});
export default router
